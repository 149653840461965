export const query = [
  "--------",
  "Daily",
  "Oil",
  "Natural Gas",
  "Power",
  "Energy",
  "Nuclear",
  "Coal",
  "Renewables",
  "Forex",
  "Carbon",
  "Electric Vehicles",
  "PPA",
];
