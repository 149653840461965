export const folder = [
  "--------",
  "Wind",
  "Solar",
  "Biomass",
  "HydroPower",
  "Hydrogen",
  "Renewables",
  "Geothermal",
];
