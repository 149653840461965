import { useState } from "react";
import { EmpLogin } from "../services/api";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  let [searchParams, setSearchParams] = useSearchParams();
  const [cookies, setCookie, removeCookie] = useCookies();

  const navigate = useNavigate();
  const nid = searchParams.get("redirect");
  async function handleSubmit(e) {
    e.preventDefault();
    const response = await EmpLogin({ login: username, password: password });
    if (response?.isSuperAdmin) {
      window.localStorage.setItem("authToken", response.auth_token);
      setCookie("authToken", response.auth_token);
      setCookie("refreshToken", response.refresh_token);
      navigate(`/${nid}`);
    } else {
      window.localStorage.removeItem("authToken");
      removeCookie("authToken");
      removeCookie("refreshToken");
    }
  }
  return (
    <div className="Login">
      <form
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <div className="form-group">
          <label htmlFor="username">
            Login:
            <input
              id="username"
              className="form-control"
              autoComplete="username"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </label>
        </div>
        <div className="form-group">
          <label htmlFor="password">
            Password:
            <input
              id="password"
              className="form-control"
              autoComplete="current-password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </label>
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default Login;
