const date = new Date();
export const postMock = {
  category: 10,
  confirmed_status: "validated",
  countries: [],
  data_source: "manual",
  editor_choice: false,
  language: "en",
  posted: date.toISOString(),
  query: null,
  folder: null,
  foto: null,
  sentiment: false,
  source: "energymarketprice.com",
  user_id: null,
  url: null,
  validated_time: null,
  text: null,
  title: null,
  title_de: null,
  title_en: null,
  title_fr: null,
  title_nl: null,
  validated_text: null,
  validated_en: null,
  validated_fr: null,
  validated_de: null,
  validated_nl: null,
};
