import Login from "./Login";
import { useCookies } from "react-cookie";

const Logout = () => {
  const [cookies, setCookie, removeCookie] = useCookies();
  window.localStorage.removeItem("authToken");
  removeCookie("authToken");
  removeCookie("refreshToken");
  return <Login />;
};

export default Logout;
