import { useCookies } from "react-cookie";
import { useNavigate, useParams } from "react-router-dom";
import { ValidateToken } from "../services/api";
import { useEffect, useState } from "react";

const AuthWrapper = (props) => {
  let [cookies, setCookie, removeCookie] = useCookies();
  const [valid, setValid] = useState(false);
  const { nid } = useParams();

  const navigate = useNavigate();
  let obj = {
    token: cookies.authToken,
    refreshToken: cookies.refreshToken,
  };

  useEffect(() => {
    async function Validate() {
      const response = await ValidateToken(obj);
      if (response.access_token) {
        window.localStorage.setItem("authToken", response.access_token);
        setValid(true);
      } else {
        navigate(`/login?redirect=${nid}`);
      }
    }
    Validate();
  }, []);

  return valid && props.children;
};

export default AuthWrapper;
